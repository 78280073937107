import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/UpdateLayout.js";
import { Divider } from 'CHAMELEON_REACT_HUB';
import ListItem from 'components/LinkCard/ListItem';
import List from '../../../components/LinkCard/List';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Glad you found your way to our documentation website!`}</p>
    <p>{`The past couple of months, there was a lot of fuzz around Mediahuis' design
system. Two months ago, things gained momentum after the dedicated Design System
team was founded. The fact that we were regarded as a full-fledged product team,
showed the internal support. This support gave our team the initial boost to
tackle the challenge head on.`}</p>
    <p>{`Now, two months later, we are super proud to present you the first fruits of our
hard work. This documentation website will not only serve as hands-on
documentation for designers and developers, but it's also meant to inform you
about our vision and mission.`}</p>
    <List mb={8} items={[{
      title: 'Vision',
      description: "What's the bigger picture that we are trying to achieve?",
      to: `/overview/vision`
    }, {
      title: 'Mission',
      description: 'What are the steps we are planning to take to fulfil our vision?',
      to: `/overview/mission`
    }]} mdxType="List" />
    <p>{`This first public release already offers a bunch of basic components that can be
used to create a wide range of patters. We've been testing out our components
with some teams already. With their feedback, a lot of progress has been made,
so that we now have the confidence to release it to more teams.`}</p>
    <List mdxType="List">
  <ListItem title="Components" description="Start building with these ready-to-use blocks!" to="/components" mb={8} mdxType="ListItem" />
    </List>
    <p>{`Big shoutout to our early-adopter teams. Without them, there would've been a lot
less functionality. Thanks to them, these components are now battle-tested and
include most of the needed functionality. That is not to say that we're not open
for further improvements and suggestions. If you're missing anything, be sure to
get in touch with us!`}</p>
    <List mdxType="List">
  <ListItem title="Contact us" description="Need help? Have suggestions? Find out how to reach us!" to="/overview/team" mb={8} mdxType="ListItem" />
    </List>
    <p>{`Lastly, we want to stress that Chameleon is a living product. Don't regard this
documentation as static, as it will evolve over time. Be sure to check in
regularly to see what new goodies we have in store!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      